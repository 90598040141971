$(document).ready(function () { // eslint-disable-line

    $('.support-videos').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3
    });

    $('.product-list').slick({
        accessibility: true,
        autoplay: false,
        draggable: true,
        infinite: false,
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 2,
                    infinite: false,
                    arrows: true,
                    centerMode: false
                }
            }
        ]
    });

    if ($(window).width() >= 768) {
        var prodSlider = $('.product-list');
        prodSlider.on('wheel', (function (e) {
            e.preventDefault();

            if (e.originalEvent.deltaY < 0) {
                $(this).slick('slickNext');
            } else {
                $(this).slick('slickPrev');
            }
        }));
    }

// Code for Show More/Show Less Content
    $('.show-more').click(function() { // eslint-disable-line
        $(this).addClass('invisible');
        $('.show-more-content').addClass('show');
        $('.show-less').addClass('visible');
    });

    $('.show-less').click(function() {// eslint-disable-line
        $(this).removeClass('visible');
        $('.show-more-content').removeClass('show');
        $('.show-more').removeClass('invisible');
    });

// Support Carousel and Video Modal
    $('.support-video-carousel').slick({
        accessibility: true,
        autoplay: false,
        draggable: true,
        infinite: false,
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    arrows: true,
                    centerMode: false
                }
            }
        ]
    });

    // Store YT video src
    var $videoSrc;
    $('.open-video-btn').click(function() {// eslint-disable-line
        $videoSrc = $(this).data('src');
    });
     // Set video src to modal, enable autoplay and disable related video options
    $('#supportVidModal').on('shown.bs.modal', function () {
        $('#supportVid').attr('src', $videoSrc + '?autoplay=1&amp;modestbranding=1&amp;showinfo=0');
    });
    // Stop Video on close and empty modal
    $('#supportVidModal').on('hide.bs.modal', function () {
        $('#supportVid').attr('src', '');
    });

    $('.panel-list li').click(function () {
        $('.panel-list li').removeClass('active');
        $(this).addClass('active');
    });
});
